:root {
    --accent: #8892AF;
    --background: #15181D;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #00000017;
}

::-webkit-scrollbar-thumb {
    background: var(--accent);
}

::-webkit-scrollbar-corner {
    background: #00000017;
}

.hr-text {
    position: relative;
    border: 0;
    text-align: center;
    opacity: .5;
    width: 100%;
}

.hr-text:before {
    content: "";
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    z-index: 1;
}

.hr-text:after {
    content: attr(data-content);
    background-color: #1e212b;
    padding: 0 10px;
    display: inline-block;
    position: relative;
    z-index: 2;
    line-height: 1.5em;
    color: white;
}

.dark.hr-text:after {
    background-color: #15181e;
}